.teacher-application-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.teacher-application-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    margin-right: 10px;
    text-align: right;
}

.teacher-application-form input,
.teacher-application-form select {
    margin-right: 10px;
    text-align: right;
}

#react-tel-input {
    margin-right: 0;
    text-align: left;
}

.teacher-application-form input,
.teacher-application-form select,
.teacher-application-form,
.react-tel-input,
.select-subject-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.teacher-application-form button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.teacher-application-form button:hover {
    background-color: #45a049;
}

.error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
}

.success-message {
    color: green;
    font-weight: bold;
    text-align: center;
    margin-top: 10px 0;
}

.right-align {
    text-align: right;
}

.submit-button {
    margin-top: 10px;
    align-self: flex-end;
}

.green {
    color: #00ff00;
    border: px solid #00ff00;
}
  
.pulsing {
    animation: pulse 1s infinite;
}
  
@keyframes pulse {
    0% {
        color: #00ff00;;
        border-color: #00ff00;;
    }
    50% {
        color: #efffef;;
        border-color: #efffef;
    }
    100% {
        color: #00ff00;;
        border-color: #00ff00;;
    }
}
  
.yellow {
    color: #ffcc00;
    border: 2px solid #ffcc00;
}
  
.red {
    color: red;
    border: 2px solid red;
}

.warning-text {
    color: red;
    font-weight: bold;
    text-align: center;
}

.circle-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #3498db;
  }
  
  .circle-loader::before,
  .circle-loader::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
  }
  
  .circle-loader::before {
    border-top-color: #3498db;
    border-bottom-color: #3498db;
    animation: spin 1.5s linear infinite;
  }
  
  .circle-loader::after {
    border-left-color: #3498db;
    border-right-color: #3498db;
    animation: spin-reverse 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes spin-reverse {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
  }
