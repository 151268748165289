.left-side label, .right-side label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    margin-right: 10px;
    text-align: right;
}

.left-side input, .right-side input,
.left-side select, .right-side select {
    margin-right: 10px;
    text-align: right;
}

.react-tel-input input {
    margin-right: 0;
    text-align: left;
}

.left-side input, .right-side input,
.left-side select, .right-side select,
.left-side, .right-side,
.react-tel-input,
.select-city-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.left-side button, .right-side button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.left-side button:hover {
    background-color: #45a049;
}

.error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
}

.success-message {
    color: green;
    font-weight: bold;
    text-align: center;
    margin-top: 10px 0;
}

.right-align {
    text-align: right;
}

.submit-button {
    margin-top: 10px;
    align-self: flex-end;
}

.left-side, .right-side > * {
    margin-bottom: 10px;
}

h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
    text-align: right;
}

.left-side, .right-side {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.left-side, .right-side {
    width: 49%;
    box-sizing: border-box;
    margin-right: 5px;
}

.student-application-form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.checkbox-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .checkbox-container label {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .checkbox-container input {
    cursor: pointer;
    height: 18px;
    width: 18px;
    opacity: 0;
  }
  
  .checkbox-container input:checked + label::before {
    content: '\2713';
    font-size: 18px;
    color: #4caf50;
  }
  
  .checkbox-container label::before {
    content: '\2610';
    font-size: 18px;
    color: #ccc;
  }
  
  .checkbox-container label:hover::before {
    color: #555;
  }
  
  .checkbox-container input:checked + label::before {
    color: #4caf50;
  }
      