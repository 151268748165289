.modal-overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 999;
}

.modal-backdrop {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
}

.modal-container {
    width: 80%;
    max-width: 600px;
    height: 80%;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    overflow-y: scroll;
    z-index: 1000;
}

.order-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.order-form input {
    font-size: 1.1rem;
    margin: 0.3rem;
    padding: 10px;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.title h1 {
    color: #000;
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
}

.title-close-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.title-close-button button {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #858585;
}

.title-close-button button:hover {
    color: #000;
}
